import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="1"
    summary="Establish a strategy and business model which promotes long-term value for shareholders."
  >
    <p>
      The Company’s business model and strategy, as detailed on the Company
      Overview page of this website, is to build a portfolio of capacity of
      syndicates at Lloyd’s.
    </p>
    <p>
      As we look forward, we shall continue to tailor the portfolio, using data
      and analytics, to optimise opportunities mindful of market conditions and
      origination opportunities. We are in active discussions with Lloyd’s to
      ensure we are in tune with the market’s ambitions, views and strategies as
      we seek opportunities to optimise the portfolio and to access the market
      beyond the current capacity portfolio. We have evolved to become a
      hard-hitting preferred Funds at Lloyd’s capital provider deploying
      significant capacity and capital on opportunities that meet or exceed our
      return requirements.
    </p>
    <p>
      We try to be innovative and creative, working with our portfolio to
      determine new ways to build our relationships and relevance. At the heart
      of what we do is supporting extraordinary Executives across management and
      underwriting. They are the ones that build and drive their businesses and
      Helios supports them in that quest with access to knowledgeable committed
      capital.
    </p>
    <p>
      The Board is committed to returning capital to shareholders and we are
      confident that we shall be able to make significant strides in this
      respect.
    </p>
  </Principle>
)
