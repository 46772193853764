import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="3"
    summary="Take into account wider stakeholder and social responsibilities and their implications for long-term success."
  >
    <p>
      The Board is committed to ensuring the Company’s business remains
      sustainable, for the community, environment and others affected by the
      Company’s activities and considers participation in quality syndicates,
      whose key characteristics are conservative reserving and a focus on profit
      rather than growth, is important in ensuring the Company’s long-term
      success and sustainability. Whilst the managing agents have direct
      responsibility for managing the syndicates and the impact of their
      businesses on the environment and the community, the managing agents’
      performance in this respect is periodically reported to and reviewed by
      the Board. Employees are also encouraged to have regard to the impact on
      the environment in the conduct of their business affairs.
    </p>
    <p>
      In addition, for a managing agent to operate at Lloyd’s there are
      ‘statements of business conduct’ (‘Lloyd’s Minimum Standards’) with which
      the managing agent must comply. Lloyd’s Minimum Standards encompass
      matters such as claims management, exposure management, governance,
      investment management, reserving and conduct (including the requirement to
      treat customers fairly). The managing agents and senior managers within
      them are also subject to regulation by the Financial Conduct Authority
      (‘FCA’) and the Prudential Regulatory Authority (‘PRA’) and must comply
      with their stringent and regular reporting requirements.
    </p>
    <p>
      On 23 March 2023 the Board approved an Environmental, Social and
      Governance (ESG) Policy Statement. Helios offers investors exposure to a
      diversified portfolio of syndicates at Lloyd’s of London. As a consequence
      Helios is inexorably aligned to the approach Lloyd’s takes with regard to
      the society as a whole in addition to those adopted by the various
      Managing Agencies.
    </p>
    <p>
      As we construct our portfolio each year, considerable emphasis is given to
      understanding individual syndicate actions with regard to ESG. This
      includes an understanding of the risks contemplated as well as the ESG
      initiatives adopted within the respective businesses and their management
      teams.
    </p>
    <p>
      We support the ESG strategy of Lloyd’s, who have outlined their ambition
      to integrate sustainability into all of Lloyd’s business activities.
      Lloyd’s have stated that embedding ESG across the market and Corporation
      is a top priority and is interwoven with their purpose of creating a
      braver world.
    </p>
    <p>
      Helios fully supports Lloyd’s approach and oversight of the market. More
      information can be found at{" "}
      <a href="https://www.lloyds.com/about-lloyds/responsible-business/esgreport2021">
        https://www.lloyds.com/about-lloyds/responsible-business/esgreport2021
      </a>
    </p>
    <p>
      The Executive Directors are in regular contact with the managing agents to
      review performance of the portfolio and discuss the market, competition,
      emerging issues and opportunities. Periodically, managing agents are
      invited to attend Board meetings to provide market updates. Information
      provided by the managing agents is considered when making decisions about
      improvements to the Company’s products or business, or about developing
      new products or exploring new opportunities.
    </p>
    <p>
      Interests of the Company’s employees are also key. Engaged, enabled and
      empowered employees who contribute to the best of their potential are
      fundamental to the long-term success of the business. We have six
      employees, including the Chief Operating Officer and the Finance Director
      and we actively seek to understand their values and what motivates
      employees and to take this into account in the way we operate. We have a
      flexible remote working model which has proved effective and worked well
      for our employees, whilst enabling the Company to adapt over the past
      three years. In all instances, two-way communication is actively sought
      and encouraged. Oversight of performance is maintained through an annual
      performance and development review process conducted by the Nomination and
      Remuneration Committee and we seek to offer appropriate levels of
      remuneration and incentives, drawing on comparator benchmark surveys as
      appropriate. The Company’s operations are reliant on key staff. The
      Directors believe that its policies, remuneration and benefit packages are
      appropriate to recruit and retain such staff.
    </p>
    <p>
      Helios also believes that diversity and inclusion initiatives are
      essential for fostering an environment where everyone feels valued,
      respected and empowered. This is a key metric for our success. While
      Helios’ workforce is small and growing, we aim to organically promote and
      provide equitable opportunities for growth and success to not only
      employees but also external partners, where possible. In addition Helios
      is a signatory to the UN Principles For Responsible Investment (
      <a href="https://www.unpri.org/">www.unpri.org</a>) and we strive to adopt
      the 6 key principles for responsible investment.
    </p>
    <p>
      Community engagement activities and philanthropic endeavours form a key
      area of focus for Helios. A new charity policy was developed in 2023 and
      we have sponsored several projects that ranged from supporting local
      communities to collaborating projects aimed at addressing societal and
      local issues.
    </p>
  </Principle>
)
