import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="2"
    summary="Seek to understand and meet shareholder needs and expectations."
  >
    <p>
      The support and engagement of our shareholders is imperative to the future
      success of the Company and the Board is committed to communicating openly
      and effectively with all shareholders and to understanding their needs and
      expectations. To achieve this, the Board encourages two-way communication
      with shareholders and responds appropriately to ensure all questions or
      issues received from them are addressed in a timely manner. The Interim
      Executive Chairman, is a principal point of contact for shareholders. His
      contact details are disclosed on all announcements made by the Company,
      together with those of Arthur Manners, Finance Director, these
      announcements can be found on the Announcements page of this website. In
      addition, contact details for our Company Secretary, Kristel van der
      Meijden, are given in all communications to shareholders about General
      Meetings and on the Advisers page of this website.
    </p>
    <p>
      The Interim Executive Chairman have regular, direct contact with large
      shareholders and make sure that their opinions are communicated to the
      Board as needed. There have been regular dialogues with shareholders
      during the year including holding briefings with analysts and other
      investors. The Company uses the Annual General Meeting as an opportunity
      to engage with its shareholders. In so far as is practicably possible, all
      Directors are expected to attend the Annual General Meeting, with the
      Chair of the Audit and Nomination and Remuneration Committees being
      available to answer shareholders’ questions. Final results of proxy
      appointments and of voting at General Meetings are disclosed on the
      Documents & Papers and Announcements pages of this website. Furthermore,
      if voting decisions stray from Company expectations, the Board will engage
      with those shareholders concerned to understand and try to address their
      concerns.
    </p>
    <p>
      We also have an ongoing dialogue with shareholders through formal
      communication of financial results on a yearly and half yearly basis and
      provide periodic market updates and press releases to ensure compliance
      with the AIM Rules.
    </p>
  </Principle>
)
