import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="7"
    summary="Evaluate board performance based on clear and relevant objectives, seeking continuous improvement."
  >
    <p>
      In addition to the Nomination and Remuneration Committee’s review of
      succession planning, the performance and effectiveness of the Board, its
      Committees and of individual Directors is reviewed annually. On an
      on-going basis Directors are encouraged to raise any issues or concerns
      with the Chairman as soon as appropriate, as the Chairman will do in the
      event there are any matters causing the Company concern.
    </p>
    <p>
      In addition, the performance of all continuing Directors is considered
      before they are proposed for re-election at each AGM.
    </p>
    <p>
      The Board conducted a formal review of its own performance and the
      performance of the Board’s Committees and the Chairman in 2022. Views and
      recommendations were implemented during 2023 and with the appointment of a
      new Chairman, CEO (2023) and COO, the next evaluation of the Board will
      take place in 2024 to measure progress against the 2022 evaluation.
    </p>
  </Principle>
)
