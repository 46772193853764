import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="4"
    summary="Embed effective risk management, considering both opportunities and threats, throughout the organisation."
  >
    <p>
      At Helios, the effective management of risk is central to our business. We
      are committed to maintaining a robust risk management framework, which
      includes comprehensive strategies, policies and procedures to manage risk
      across all levels of our operations. Our team has regular communication
      with syndicates to understand how they manage a wide range of risks,
      including underwriting, operational, market, credit and liquidity risks.
      We also understand the importance of stress testing and scenario analysis
      in managing risk. We regularly conduct these exercises to assess the
      resilience of the Helios Capacity Portfolio under different conditions.
      The results of these analyses are used to inform our strategic decision
      making and capital allocation processes.
    </p>
    <p>
      Designing and implementing an effective risk management framework is a
      continuous process, and we are committed to its ongoing development to
      ensure that it remains fit for purpose as our business evolves. We are
      confident that our approach to risk management positions us well to
      mitigate potential risks and capitalise on opportunities as they arise.
    </p>
    <p>
      The majority of risks to the Company’s future cash flows arise from the
      subsidiaries’ participations in the results of Lloyd’s syndicates, as
      detailed on pages 13 to 14 and 44 to 48 (inclusive) of the Company’s 31
      December 2023 Report and Accounts. These risks are mostly managed by the
      syndicates’ managing agents. The Company’s role in managing these risks,
      together with its subsidiaries and members’ agents, is focussed on
      selection of the syndicate participations; monitoring syndicate
      performance; and purchasing appropriate member level reinsurance.
    </p>
    <p>
      The Executive Directors meet regularly to review the Company’s ongoing
      trading performance, discuss budgets and forecasts and any new or emerging
      risks associated with ongoing trading. The Board regularly reviews actual
      performance against budgets and forecasts, as well as anything brought to
      its attention. Project milestones and timelines are also reviewed
      regularly.
    </p>
    <p>
      Opportunities and threats to the Company’s business model and strategy are
      also periodically considered at dedicated Board strategy meetings.
    </p>
  </Principle>
)
