import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import ColumnLayout from "layouts/column-layout"
import Divider from "components/divider"
import ChairmanQuote from "./_chairman-quote"
import KeyDocuments from "pages/about-helios-underwriting/corporate-governance/key-documents"
import QCACodePrinciple1 from "./_qca-code-principles/_1"
import QCACodePrinciple2 from "./_qca-code-principles/_2"
import QCACodePrinciple3 from "./_qca-code-principles/_3"
import QCACodePrinciple4 from "./_qca-code-principles/_4"
import QCACodePrinciple5 from "./_qca-code-principles/_5"
import QCACodePrinciple6 from "./_qca-code-principles/_6"
import QCACodePrinciple7 from "./_qca-code-principles/_7"
import QCACodePrinciple8 from "./_qca-code-principles/_8"
import QCACodePrinciple9 from "./_qca-code-principles/_9"
import QCACodePrinciple10 from "./_qca-code-principles/_10"
import CTA from "components/call-to-action"

export default () => (
  <Layout>
    <Helmet>
      <title>Corporate governance | Helios Underwriting</title>
    </Helmet>
    <ColumnLayout aside={<KeyDocuments />}>
      <section className="padding-md">
        <h1 className="h1 mb-40">Corporate governance</h1>
        <ChairmanQuote />
      </section>

      <section className="padding-md">
        <Divider color="terracotta" />
        <h2 className="h2 mt-20">Principles of the QCA code</h2>
        <p className="u-color-emperor">
          <span className="u-bold">Last updated</span>
          &ensp;4 October 2024
        </p>
      </section>

      <QCACodePrinciple1 />
      <QCACodePrinciple2 />
      <QCACodePrinciple3 />
      <QCACodePrinciple4 />
      <QCACodePrinciple5 />
      <QCACodePrinciple6 />
      <QCACodePrinciple7 />
      <QCACodePrinciple8 />
      <QCACodePrinciple9 />
      <QCACodePrinciple10 />

      <CTA title="Advisers" link="/about-helios-underwriting/advisers" />
    </ColumnLayout>
  </Layout>
)
