import React from "react"

class ChairmanQuote extends React.Component {
  render() {
    const chairmanImageURL = "/assets/board-portraits-circular-michael-wade.png"

    return (
      <blockquote className="c-blockquote">
        <div className="c-blockquote__intro">
          Helios Underwriting Plc is incorporated in the UK and the Company’s
          shares are traded on the AIM Market of the London Stock Exchange. As a
          result, the Company is subject to the UK’s City Code on Takeovers and
          Mergers.
        </div>
        <div className="c-blockquote__body">
          <p>
            The Board is committed to achieving a high standard of corporate
            governance within the Company and its subsidiaries, which it seeks
            to demonstrate by adopting and being compliant with the principles
            of the Quoted Companies Alliance’s Corporate Governance Code (‘the
            QCA Code’). The Board considers the QCA Code is relevant and
            appropriate for the Company as the ten principles of the QCA Code
            focus on “pursuit of medium to long-term value for shareholders
            without stifling the entrepreneurial spirit in which the company was
            created”.
          </p>
          <p>
            Accordingly, the Board ensures the Company has a strong governance
            framework embedded within its culture and applies the principles of
            the QCA Code. The Board requires that the Company’s strategy of
            building a portfolio of underwriting capacity at Lloyd’s through the
            purchase of corporate members is carried out in a manner that is
            ethical and sustainable. This is achieved by focusing on syndicate
            portfolios comprising quality syndicates which are managed by
            leading managing agents at Lloyd’s. The Directors and the Board
            determine, support and will observe the Company’s ethical values in
            order to promote and preserve the Company’s reputation. The Board
            periodically reviews the governance framework and, as the Company
            evolves, will make such improvements and changes as considered
            necessary. Details are set out below of how the Company addresses
            and complies with the principles of the QCA Code and further
            information will be included in our next Annual Report and Accounts.
          </p>
          <p>
            This website contains the information required to be disclosed by
            AIM Rule 26.
          </p>

          <div className="c-blockquote__footer">
            <div
              className="c-blockquote__image"
              style={{ backgroundImage: `url(${chairmanImageURL})` }}
            ></div>
            <div className="c-blockquote__attribution">
              <div className="u-bold">Michael Wade</div>
              <div>Executive Chairman</div>
            </div>
          </div>
        </div>
      </blockquote>
    )
  }
}

export default ChairmanQuote
