import React from 'react'
import AnimateHeight from 'react-animate-height'

import styles from 'components/principle.module.scss'

class Principle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }

    this.handleAccordionToggle = this.handleAccordionToggle.bind(this)
  }

  handleAccordionToggle() {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { summary, number, children } = this.props
    const {height} = this.state

    return(
      <div key={number} className={styles.block}>
        <h2 className="h4 u-color-steel">
          QCA Code Principle {number}
        </h2>
        <p className="article-title">{summary}</p>

        <AnimateHeight
          duration={ 300 }
          height={ height }
        >
          <div className={styles.details}>{children}</div>
        </AnimateHeight>

        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={this.handleAccordionToggle}>
            { height === 0 ? 'Read details' : 'Hide details' }
          </button>
        </div>
      </div>
    )
  }
}

export default Principle