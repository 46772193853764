import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="8"
    summary="Promote a corporate culture that is based on ethical values and behaviours."
  >
    <p>
      The Board requires that the Company’s strategy of building a portfolio of
      underwriting capacity at Lloyd’s through the purchase of corporate members
      is carried out in a manner that is ethical and sustainable. This is
      achieved by focussing on syndicate portfolios comprising quality
      syndicates which are managed by leading managing agents at Lloyd’s.
    </p>
    <p>
      The Directors and the Board determine, support and will observe the
      Company’s ethical values in order to promote and preserve the Company’s
      reputation. It is the policy of the Company to conduct business in an
      honest and ethical manner and steps taken by the Company to prevent modern
      slavery, human rights violations or trafficking in its business and supply
      chains are detailed in the Company’s Modern Slavery Statement.
      Furthermore, the Company takes a zero-tolerance approach to bribery and
      corruption and is committed to acting professionally, fairly and with
      integrity in all its business dealings and relationships, wherever it
      operates, and implementing and enforcing effective systems to counter
      bribery. How this is ensured is detailed in the Company’s Anti-Corruption
      and Bribery Policy which, together with the Company’s Modern Slavery
      Statement, is available in the Documents and Papers section of the
      Company’s website.
    </p>
    <p>
      The Board has adopted a Share Dealing Code and Disclosure Policy that any
      Director, employee or person engaged by the Company who is in possession
      of ‘inside information’ must comply with. All such persons are prohibited
      from trading in the Company’s securities if they are in possession of
      ‘inside information’. The Directors will also comply with Rule 21 of the
      AIM Rules relating to Directors’ dealings.
    </p>
    <p>
      In addition, in the interests of openness and transparency, a record is
      kept of Directors’ interests and of persons closely associated with them
      to ensure they have no conflicts or possible conflicts with the interests
      of the Company. The Board also reviews whether there are relationships or
      circumstances which do, or are likely to, affect independence of the
      Non-executive Directors.
    </p>
  </Principle>
)
